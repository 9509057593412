import {fabric} from 'fabric-with-erasing';
// eslint-disable-next-line import/no-unresolved
import {ICanvasOptions} from 'fabric/fabric-impl';

/**
 * Adds the history feature to the fabric-with-erasing library. With this feature we are able to undo and redo
 * drawings in the canvas.
 * https://github.com/alimozdemir/fabric-history/blob/master/src/index.js
 * https://www.npmjs.com/package/fabric-history
 */
export class StembleFabric extends fabric.Canvas {
  constructor(element: HTMLCanvasElement | string | null, options?: ICanvasOptions) {
    super(element, options);
    this._historyInit();
  }
  private _historyInit() {
    this.historyUndo = [];
    this.historyRedo = [];
    this.extraProps = ['selectable', 'editable'];
    this.historyNextState = this._historyNext();

    this.on(this._historyEvents());
  }
  /**
   * Returns current state of the string of the canvas
   */
  private _historyNext() {
    return JSON.stringify(this.toDatalessJSON(this.extraProps));
  }
  /**
   * Returns an object with fabricjs event mappings
   */
  private _historyEvents() {
    return {
      'object:removed': this._historySaveAction,
      'path:created': this._historySaveAction,
      'selection:updated': this._historySaveAction,
      'selection:created': this._historySaveAction,
    };
  }
  /**
   * Remove the custom event listeners
   */
  private _historyDispose() {
    this.off(this._historyEvents());
  }
  /**
   * It pushes the state of the canvas into history stack
   */
  private _historySaveAction() {
    if (this.historyProcessing) {
      return;
    }
    const json = this.historyNextState;
    this.historyUndo.push(json);
    this.historyNextState = this._historyNext();
    this.fire('history:append', {json: json});
  }
  /**
   * Undo to latest history.
   * Pop the latest state of the history. Re-render.
   * Also, pushes into redo history.
   */
  public undo(callback: any) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.
    this.historyProcessing = true;
    const history = this.historyUndo.pop();
    if (history) {
      // Push the current state to the redo history
      this.historyRedo.push(this._historyNext());
      this.historyNextState = history;
      this._loadHistory(history, 'history:undo', callback);
    } else {
      this.historyProcessing = false;
    }
  }
  /**
   * Redo to latest undo history.
   */
  public redo(callback: any) {
    // The undo process will render the new states of the objects
    // Therefore, object:added and object:modified events will triggered again
    // To ignore those events, we are setting a flag.
    this.historyProcessing = true;
    const history = this.historyRedo.pop();
    if (history) {
      // Every redo action is actually a new action to the undo history
      this.historyUndo.push(this._historyNext());
      this.historyNextState = history;
      this._loadHistory(history, 'history:redo', callback);
    } else {
      this.historyProcessing = false;
    }
  }
  private _loadHistory(history: any, event: any, callback: () => void) {
    const that = this;

    this.loadFromJSON(history, function () {
      that.renderAll();
      that.fire(event);
      that.historyProcessing = false;

      if (callback && typeof callback === 'function') {
        callback();
      }
    });
  }
  /**
   * Clear undo and redo history stacks
   */
  clearHistory() {
    this.historyUndo = [];
    this.historyRedo = [];
    this.fire('history:clear');
  }
  /**
   * On the history
   */
  onHistory() {
    this.historyProcessing = false;
    this._historySaveAction();
  }
  /**
   * Off the history
   */
  offHistory() {
    this.historyProcessing = true;
  }
}
