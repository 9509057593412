import {Ref} from '@vue/composition-api';

export function useCanvasCopy(fabricCanvas: Ref<any>) {
  function copyObject() {
    const canvas = fabricCanvas.value;
    let activeObjects: any;
    canvas.getActiveObject()?.clone((cloned: any) => {
      activeObjects = cloned;
      if (!activeObjects) {
        return;
      }
      activeObjects.clone(function (clone: any) {
        canvas.discardActiveObject();
        clone.set({
          left: activeObjects.left + 10,
          top: activeObjects.top + 10,
        });
        if (clone.type === 'activeSelection') {
          clone.canvas = canvas;
          clone.forEachObject(function (obj: any) {
            canvas.add(obj);
          });
          clone.setCoords();
        } else {
          canvas.add(clone);
        }
        activeObjects.top += 10;
        activeObjects.left += 10;
        canvas.setActiveObject(clone);
      });
      canvas.requestRenderAll();
    });
  }
  return {copyObject};
}
