import {onMounted, Ref, ref, shallowRef, watch, watchEffect} from '@vue/composition-api';
import {StembleFabric} from '@/common/utils/StembleFabric';
import {fabric} from 'fabric-with-erasing';
import {useCanvasCopy} from '@/common/composables/useCanvasCopy';

interface UseStembleCanvasOptions {
  brushSize: Ref<number>;
  brushColor: Ref<string>;
  emit: any;
  canvas: Ref<HTMLCanvasElement | null>;
  toolbar: Ref<any | null>;
}
export function useStembleCanvas(props: UseStembleCanvasOptions) {
  const fabricCanvas = shallowRef<any | null>(null);
  const cursorMode = ref<'draw' | 'erase' | 'selection' | ''>('');
  const showInfoTooltip = ref<Boolean>(false);
  watchEffect(() => {
    if (!fabricCanvas.value) {
      return;
    }
    fabricCanvas.value.freeDrawingBrush.color = props.brushColor.value;
  });
  watchEffect(() => {
    if (props.toolbar.value) {
      fabricCanvas.value?.setWidth(props.toolbar.value.$el.clientWidth);
    }
  });
  watch(cursorMode, async () => {
    switch (cursorMode.value) {
      case 'draw':
        fabricCanvas.value.freeDrawingBrush = new fabric.PencilBrush(fabricCanvas.value);
        fabricCanvas.value.freeDrawingBrush.width = props.brushSize.value;
        fabricCanvas.value.isDrawingMode = true;
        showInfoTooltip.value = true;
        break;
      case 'erase':
        fabricCanvas.value.freeDrawingBrush = new fabric.EraserBrush(fabricCanvas.value);
        fabricCanvas.value.freeDrawingBrush.width = props.brushSize.value * 6;
        fabricCanvas.value.isDrawingMode = true;
        showInfoTooltip.value = false;
        break;
      case 'selection':
        showInfoTooltip.value = false;
        fabricCanvas.value.isDrawingMode = false;
        break;
    }
  });
  const updateModelValue = () => {
    const canvas = fabricCanvas.value;
    props.emit('update:modelValue', canvas.toDataURL({format: 'png'}));
    canvas.requestRenderAll();
  };

  onMounted(() => {
    fabricCanvas.value = new StembleFabric(props.canvas.value, {
      selection: true,
    });
    fabricCanvas.value.freeDrawingBrush.width = props.brushSize.value;

    window.onresize = () => {
      if (props.toolbar.value) {
        fabricCanvas.value.setWidth(props.toolbar.value.$el.clientWidth);
      }
    };

    fabricCanvas.value.on('path:created', updateModelValue);
    fabricCanvas.value.on('canvas:cleared', updateModelValue);
  });

  return {
    canvas: props.canvas,
    cursorMode,
    toolbar,
    showInfoTooltip,
    setPencil() {
      cursorMode.value = 'draw';
    },
    setEraser() {
      cursorMode.value = 'erase';
    },
    setSelection() {
      cursorMode.value = 'selection';
    },
    clear() {
      const canvas = fabricCanvas.value;
      if (canvas) {
        canvas.clear();
        canvas.clearHistory();
      }
    },
    undo() {
      const canvas = fabricCanvas.value;
      canvas.undo();
      props.emit('update:modelValue', canvas.toDataURL({format: 'png'}));
    },
    redo() {
      const canvas = fabricCanvas.value;
      canvas.redo();
      props.emit('update:modelValue', canvas.toDataURL({format: 'png'}));
    },
    ...useCanvasCopy(fabricCanvas),
  };
}
