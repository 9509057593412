






























































import {defineComponent, ref, toRef} from '@vue/composition-api';
import {useStembleCanvas} from '../composables/useStembleCanvas';

export default defineComponent({
  name: 'StembleCanvas',
  plugins: [],
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    canvasHeight: {
      type: Number,
      default: 200,
    },
    modelValue: {
      type: String,
      default: null,
    },
    brushSize: {
      type: Number,
      default: 2,
    },
    brushColor: {
      type: String,
      default: '#444444',
    },
  },
  setup(props, {emit}) {
    const can = ref<HTMLCanvasElement | null>(null);
    const toolbar = ref<any | null>(null);

    return {
      ...useStembleCanvas({
        brushSize: toRef(props, 'brushSize'),
        brushColor: toRef(props, 'brushColor'),
        emit,
        canvas: can,
        toolbar,
      }),
      can,
      toolbar,
    };
  },
});
